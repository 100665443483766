import React from "react";
import "./Faq.css";
import img from "../../assets/testimonial-01.svg";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

export default function Faq() {
  return (
    <section className="container faq-section">
      <div className="row">
        <div className="col-12">
          <h1 className="contact-h1">FAQ'S</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="mySwiper"
          >
            {" "}
            <SwiperSlide>
              <div className="row">
                <div className="col-lg-5 col-12">
                  <div className="faq-div-1">
                    <div className="faq-img-div">
                      <img src={img} alt="img" />
                    </div>
                    <div className="faq-circle-div">
                      <h4>Question?</h4>
                      <p>
                        what is the eligibility criteria to become a Gbazaar DSA
                        Partner?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-12">
                  <div className="faq-content-outer-div  d-flex justify-content-center align-items-center">
                    <div className="faq-content-div ">
                      <p>
                        There is no limit for becoming a DealSofLoan DSA
                        Partner. Anyone of Indians between 18+ to 65 years of
                        age can register as a partner with us.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="row">
                <div className="col-lg-5 col-12">
                  <div className="faq-div-1">
                    <div className="faq-img-div">
                      <img src={img} alt="img" />
                    </div>
                    <div className="faq-circle-div">
                      <h4>Question?</h4>
                      <p>
                        what is the eligibility criteria to become a Gbazaar DSA
                        Partner?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-12">
                  <div className="faq-content-outer-div  d-flex justify-content-center align-items-center">
                    <div className="faq-content-div ">
                      <p>
                        There is no limit for becoming a DealSofLoan DSA
                        Partner. Anyone of Indians between 18+ to 65 years of
                        age can register as a partner with us.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="row">
                <div className="col-lg-5 col-12">
                  <div className="faq-div-1">
                    <div className="faq-img-div">
                      <img src={img} alt="img" />
                    </div>
                    <div className="faq-circle-div">
                      <h4>Question?</h4>
                      <p>
                        what is the eligibility criteria to become a Gbazaar DSA
                        Partner?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-12">
                  <div className="faq-content-outer-div  d-flex justify-content-center align-items-center">
                    <div className="faq-content-div ">
                      <p>
                        There is no limit for becoming a DealSofLoan DSA
                        Partner. Anyone of Indians between 18+ to 65 years of
                        age can register as a partner with us.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
}

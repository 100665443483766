import React, { useState, useRef, useEffect } from "react";
import "./Navbar.css";
import { Link } from "react-scroll";
import logo from "../../assets/logo-1.png";

function Navbar() {
  const wrapperRef = useRef();
  // let navigate = useNavigate();
  const [nav, setNav] = useState(true);
  const [color, setColor] = useState(false);
  const [innerNav, setInnerNav] = useState(false);
  const [innerNav1, setInnerNav1] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  // outside click hide

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setInnerNav1(false);
      setInnerNav(false);
    }
  };
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setInnerNav(false);
      setInnerNav1(false);
      // setNav( !nav );
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  // handleClickOutside();

  window.addEventListener("scroll", changeColor);
  // eslint-disable-next-line no-unused-vars
  function handleClick() {
    setNav({ clicked: !nav.clicked });
  }
  // eslint-disable-next-line no-unused-vars
  function handleInner() {
    setInnerNav({ clicked: !innerNav.clicked });
  }
  // eslint-disable-next-line no-unused-vars
  function handleInner1() {
    setInnerNav1({ clicked: !innerNav1.clicked });
  }

  return (
    <>
      <nav>
        <div className="container-fluid navbar-container px-0" ref={wrapperRef}>
          <div
            className={
              color
                ? "navbar-content-div navbar-bg d-flex flex-row justify-content-between py-3 "
                : "navbar-content-div d-flex flex-row justify-content-between py-3 "
            }
          >
            <Link
              className="navbar-logo uc d-block"
              activeClass="active"
              style={{ cursor: "pointer" }}
              to="home"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <img src={logo} alt="img" />
            </Link>

            <div
              className={
                !nav ? "nav-ul-div my-auto" : "nav-ul-div-hide my-auto"
              }
            >
              <ul className="nav-ul d-flex flex-lg-row flex-column justify-content-around m-0 p-0 align-items-center">
                <li className="nav-li me-4">
                  <Link
                    activeClass="active"
                    style={{ cursor: "pointer" }}
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="uc"
                    onClick={() => setNav(!nav)}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-li me-4">
                  <Link
                    activeClass="active"
                    to="about"
                    style={{ cursor: "pointer" }}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="uc"
                    onClick={() => setNav(!nav)}
                  >
                    About
                  </Link>
                </li>

                <li className="nav-li me-4">
                  <Link
                    activeClass="active"
                    to="service"
                    style={{ cursor: "pointer" }}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="uc"
                    onClick={() => setNav(!nav)}
                  >
                    Service
                  </Link>
                </li>

                <li className="nav-li me-4">
                  <Link
                    activeClass="active"
                    to="enquiry"
                    style={{ cursor: "pointer" }}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="uc"
                    onClick={() => setNav(!nav)}
                  >
                    Enquiry
                  </Link>
                </li>

                {/* <li className="nav-li me-4">
                  <Link
                    activeClass="active"
                    to="registration"
                    style={{ cursor: "pointer" }}
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="uc cursor-pointer"
                    onClick={() => setNav(!nav)}
                  >
                    DSA Registration
                  </Link>
                </li>

                <li className="nav-li me-4">
                  <a
                    href="https://c-bazaar-frontend.vercel.app/home"
                    className="uc cursor-pointer"
                    onClick={() => setNav(!nav)}
                  >
                    Log In
                  </a>
                </li>

                <li className="list-remove  me-lg-4 me-2">
                  <a
                    href="https://c-bazaar-frontend.vercel.app/signup"
                    style={{ cursor: "pointer" }}
                    className="navbar-signin-btn btn btn-sm px-4"
                  >
                    Sign In
                  </a>
                </li> */}
              </ul>
            </div>

            <div
              className={
                nav ? "hamburge d-lg-none" : "hamburger d-lg-none change"
              }
              onClick={() => setNav(!nav)}
            >
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;

import React from "react";
import "./Home.css";
import banner from "../../assets/banner_image.svg";
import element1 from "../../assets/banner-elements-01.svg";
import element2 from "../../assets/banner-elements-02.svg";
import { Link } from "react-scroll";

function Home() {
  return (
    <>
      <section
        className="container-fluid px-0 mx-0 home-section position-relative"
        id="home"
      >
        <div className="home-content-div">
          <div className="row px-0 mx-0">
            <div className="col-lg-6 col-12 d-flex align-items-center justify-content-center position-relative">
              <div className="element-1">
                <img src={element1} alt="element" />
              </div>
              <div className="element-2">
                <img src={element1} alt="element" />
              </div>
              <div className="element-3">
                <img src={element2} alt="element" />
              </div>
              <div>
                <div>
                  <h4 className="home-title">
                    View Credit Score for Customized <br /> Offers! Get your
                    Dream Card & Loan
                  </h4>
                  <p className="mt-sm-4 mt-3 home-p">
                    Blends banking and financial services on a single platform.
                    It helps with personalized benefits for credit cards & loans
                    for a brighter future.
                  </p>
                  <Link
                    activeClass="active"
                    to="about"
                    style={{ cursor: "pointer" }}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="uc btn btn-md btn-success home-btn border-0 mt-sm-4 mt-3"
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-lg-0 mt-5">
              <div className="home-image">
                <img src={banner} alt="img" />
              </div>
            </div>
          </div>
        </div>

        <div className="home-card-section pt-lg-0 pt-5">
          <div className="card home-card mx-auto">
            <div className="card-body d-flex flex-row align-items-center justify-content-around">
              <div>
                <div className="home-card-div-1">
                  <h5 className="home-card-count">1000+</h5>
                  <p className="home-card-p mt-3">
                    Locations <br /> covered
                  </p>
                </div>
              </div>
              <div className="home-card-div-line"></div>
              <div>
                <div className="home-card-div-1">
                  <h5 className="home-card-count">50+</h5>
                  <p className="home-card-p mt-3">
                    Partner <br /> Bank
                  </p>
                </div>
              </div>
              <div className="home-card-div-line"></div>
              <div>
                <div className="home-card-div-1">
                  <h5 className="home-card-count">1000Cr+</h5>
                  <p className="home-card-p mt-3">Disbursement</p>
                </div>
              </div>
              <div className="home-card-div-line"></div>
              <div>
                <div className="home-card-div-1">
                  <h5 className="home-card-count">175000+</h5>
                  <p className="home-card-p mt-3">Partners</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;

import React from "react";
import "./Footer.css";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import logo from "../../assets/logo-1.png";
import { Link } from "react-scroll";

export default function Footer() {
  return (
    <section className="footer-section container">
      <div className="row px-0 mx-0 py-5">
        <div className="col-md-2 col-12">
          <Link
            className="footer-logo-div ms-xxl-4 ms-0 uc d-block"
            activeClass="active"
            style={{ cursor: "pointer" }}
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <img src={logo} className="footer-logo" alt="logo" />
          </Link>

          <div className="footer-social mt-4 ms-xxl-4 ms-0">
            <h6 className="mb-2">Follow us on</h6>

            <div className="d-flex align-items-center">
              <div className="footer-social-div me-xxl-2 me-1">
                <FaFacebookF className="social-icon" />
              </div>
              <div className="footer-social-div me-xxl-2 me-1">
                <FaLinkedinIn className="social-icon" />
              </div>

              <div className="footer-social-div me-xxl-2 me-1">
                <FaInstagram className="social-icon" />
              </div>
              <div className="footer-social-div me-xxl-2 me-1">
                <FaTwitter className="social-icon" />
              </div>
              <div className="footer-social-div me-xxl-2 me-1">
                <FaWhatsapp className="social-icon" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-2 col-12">
          <div className="footer-content-div mt-md-0 mt-5">
            <h4 className="footer-content-h4">Credit Card</h4>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Best Credit Cards</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">
                Check your Credit Score
              </p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Cibil</p>
            </a>
          </div>
        </div>

        <div className="col-md-2 col-12">
          <div className="footer-content-div">
            <h4 className="footer-content-h4">Loan</h4>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Personal Loan</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Home Loan</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Educational Loan</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Business Loan</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Vehicle Loan</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Gold Loan</p>
            </a>
          </div>
        </div>

        <div className="col-md-2 col-12">
          <div className="footer-content-div">
            <h4 className="footer-content-h4">Insurance</h4>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Life Insurance</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Home Insurance</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Health Insurance</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Vehicle Insurance</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">
                Property Insurance
              </p>
            </a>
          </div>
        </div>

        <div className="col-md-2 col-12">
          <div className="footer-content-div">
            <h4 className="footer-content-h4">Investment</h4>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Mutual Funds</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Fixed Deposit</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Recurring Deposit</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">Demat & Trading</p>
            </a>
            <a href="/">
              <p className="footer-content-p mt-md-4 mt-3">
                Government Schemes
              </p>
            </a>
          </div>
        </div>

        <div className="col-md-2 col-12">
          <div className="footer-content-div">
            <h4 className="footer-content-h4">Connect with us</h4>
            <div className="footer-div-form mt-4">
              <a href="mailto:gbazaar@gmail.com">
                <p className="footer-content-p">gbazaar@gmail.com</p>
              </a>
              <a className="mt-md-4 mt-3" href="tel:+9187788**777">
                <p className="footer-content-p">Call us: 87788**777</p>
              </a>
              <a href="/" className="mt-md-4 mt-3">
                <p className="footer-content-p">
                  57, Zaylo street, <br />
                  Sacramento, <br />
                  California.
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="profile-icon-section">
    <div className="profile-icon-content-div d-flex align-items-center">
      <h6 className="profile-icon-h6">Follow Us :</h6>
      <div className="profile-icon-div">
     
        <i className="fa-brands fa-facebook-f fs-6"></i>
      </div>
      <div className="profile-icon-div">
       
        <i className="fa-brands fa-twitter fs-6"></i>
      </div>
      <div className="profile-icon-div">
      
        <i className="fa-brands fa-instagram fs-6"></i>
      </div>
      <div className="profile-icon-div">
       
        <i className="fa-brands fa-linkedin-in fs-6"></i>
      </div>
      <div className="profile-icon-div">
      
        <i className="fa-brands fa-whatsapp fs-6"></i>
      </div>
    </div>
  </div>

  <div className="footer-policy-section">
    <div className="footer-policy-div">
      <p className="footer-policy-h6">
        Copyright &#169; 2022
        <a href="/" className="footer-policy-span">C-Bazaar Team</a>
      </p>
    </div>
    <div className="footer-policy-div">
      <p className="footer-policy-h6">
        Developed by
        <a
          className="footer-policy-span"
          target="_blank"
          href="https://www.cloudinlabs.com/"
          >Cloudin Software Tech Labs</a
        >
      </p>
    </div>
  </div> */}
    </section>
  );
}

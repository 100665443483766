import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import "./Intro.css";
import icon1 from "../../assets/icons/introduction-section/introduction-icon-1.svg";
import icon2 from "../../assets/icons/introduction-section/introduction-icon-2.svg";
import icon3 from "../../assets/icons/introduction-section/introduction-icon-3.svg";
import icon4 from "../../assets/icons/introduction-section/introduction-icon-4.svg";
import icon5 from "../../assets/icons/introduction-section/introduction-icon-5-01.svg";
import icon6 from "../../assets/icons/introduction-section/introduction-icon-6.svg";
import icon7 from "../../assets/icons/introduction-section/introduction-icon-7-01.svg";
import icon8 from "../../assets/icons/introduction-section/introduction-icon-8-01.svg";

function Intro() {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.clientWidth);
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWidth(ref.current.clientWidth);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <section className="container-fluid intro-section" ref={ref}>
        <div className="intro-content-div">
          <h4 className="intro-title">Introduction</h4>
          <div className="row">
            <div className="card-group">
              <div className="col-lg-3 col-sm-6 col-12 p-0">
                {width > 576 ? (
                  <div
                    className={
                      width < 992
                        ? "card intro-card-1 h-100"
                        : "card intro-card-1 h-100"
                    }
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-start ">
                        <div className="intro-icon">
                          <img src={icon2} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Get Free Credit Report from multiple bureaus.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card intro-card-new h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-sm-start align-items-center py-sm-0 py-3">
                        <div className="intro-icon">
                          <img src={icon2} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Get Free Credit Report from multiple bureaus.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-3 col-sm-6 col-12 p-0">
                {width > 576 ? (
                  <div
                    className={
                      width < 992
                        ? "card intro-card-2 h-100"
                        : "card intro-card-1 h-100"
                    }
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-start">
                        <div className="intro-icon">
                          <img src={icon3} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Multiple choices of from 6+ partners, 5+NBFCs and
                            3+FI’s.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card intro-card-new h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-sm-start align-items-center py-sm-0 py-3">
                        <div className="intro-icon">
                          <img src={icon3} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Multiple choices of from 6+ partners, 5+NBFCs and
                            3+FI’s.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-3 col-sm-6 col-12 p-0">
                {width > 576 ? (
                  <div className="card intro-card-1 h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-start">
                        <div className="intro-icon">
                          <img src={icon1} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Personalized Customization of Credit Card and loan
                            services with minimal necessary documentations.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card intro-card-new h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-sm-start align-items-center py-sm-0 py-3">
                        <div className="intro-icon">
                          <img src={icon1} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Personalized Customization of Credit Card and loan
                            services with minimal necessary documentations.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-3 col-sm-6 col-12 p-0">
                {width > 576 ? (
                  <div className="card intro-card-2 h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-start">
                        <div className="intro-icon">
                          <img src={icon4} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Choose & Apply for the most preferable card & loan
                            with ease, digitally.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card intro-card-new h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-sm-start align-items-center py-sm-0 py-3">
                        <div className="intro-icon">
                          <img src={icon4} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Choose & Apply for the most preferable card & loan
                            with ease, digitally.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* 2 */}

              <div className="col-lg-3 col-sm-6 col-12 p-0">
                {width > 576 ? (
                  <div
                    className={
                      width < 992
                        ? "card intro-card-1 h-100"
                        : "card intro-card-3 h-100"
                    }
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-start">
                        <div className="intro-icon">
                          <img src={icon5} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Show interest on investment and insurance which
                            uplifts lives to the next step.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card intro-card-new h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-sm-start align-items-center py-sm-0 py-3">
                        <div className="intro-icon">
                          <img src={icon5} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Show interest on investment and insurance which
                            uplifts lives to the next step.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-3 col-sm-6 col-12 p-0">
                {width > 576 ? (
                  <div
                    className={
                      width < 992
                        ? "card intro-card-2 h-100"
                        : "card intro-card-3 h-100"
                    }
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-start">
                        <div className="intro-icon">
                          <img src={icon6} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Stay updated with the current information on
                            governments schemes and other financial services.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card intro-card-new h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-sm-start align-items-center py-sm-0 py-3">
                        <div className="intro-icon">
                          <img src={icon6} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Stay updated with the current information on
                            governments schemes and other financial services.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-3 col-sm-6 col-12 p-0">
                {width > 576 ? (
                  <div className="card intro-card-3 h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-start">
                        <div className="intro-icon">
                          <img src={icon7} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Track applications and their status wisely at
                            anytime.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card intro-card-new h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-sm-start align-items-center py-sm-0 py-3">
                        <div className="intro-icon">
                          <img src={icon7} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Track applications and their status wisely at
                            anytime.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-3 col-sm-6 col-12 p-0">
                {width > 576 ? (
                  <div className="card intro-card-4 h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-start">
                        <div className="intro-icon">
                          <img src={icon8} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Real-time calculations on interest rate, principal
                            amount and tenure periods for the Loan amount.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card intro-card-new h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-sm-start align-items-center py-sm-0 py-3">
                        <div className="intro-icon">
                          <img src={icon8} alt="img" />
                        </div>
                        <div>
                          <p className="intro-p">
                            Real-time calculations on interest rate, principal
                            amount and tenure periods for the Loan amount.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Intro;

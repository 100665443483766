import React, { useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

export default function Contact() {
  const form = useRef();
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      message: "",
      loan: null,
      phone: "",
      address: "",
      amount: "",
      pan_number: "",
    },
  });

  const [data, setData] = useState({});

  const onInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_deu2loh",
        "template_0jvkw2m",
        form.current,
        "LWQbkr3jo4IxvLIKe"
      )
      .then(
        (result) => {
          reset();
          Swal.fire(
            "Good job!",
            "Message Send Successfully :)",
            "success",
            "Close"
          );
        },
        (error) => {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong message could not be sent :) ",
            icon: "error",
            button: "ok!",
          });
        }
      );
  };

  function keyPress(event, pattern) {
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  return (
    <section className="container contact-container" id="enquiry">
      <div className="row">
        <div className="col-12">
          <h1 className="contact-h1">Write us a message</h1>
        </div>
      </div>
      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <div className="row px-5">
          <div className="col-sm-6 col-12 py-2">
            <label for="firstname" className="contact-label">
              First Name
            </label>
            <input
              id="firstname"
              type="text"
              placeholder="e.g. John"
              className="contact-input form-control outline-none shadow-none"
              onChange={(e) => onInputChange(e)}
              {...register("firstname", {
                required: "First Name is required",
                maxLength: {
                  value: 55,
                  message: "Max length is 55",
                },
              })}
            />
            <span className="text-danger fs-12">
              {errors.firstname && errors.firstname.message}
            </span>
          </div>

          <div className="col-sm-6  col-12 py-2">
            <label for="lastname" className="contact-label">
              Last Name
            </label>
            <input
              id="lastname"
              type="text"
              placeholder="e.g. Deo"
              onChange={(e) => onInputChange(e)}
              className="contact-input form-control outline-none shadow-none"
              {...register("lastname", {
                required: "Last Name is required",
                maxLength: {
                  value: 55,
                  message: "Max length is 55",
                },
              })}
            />
            <span className="text-danger fs-12">
              {errors.lastname && errors.lastname.message}
            </span>
          </div>

          <div className="col-sm-6  col-12  py-2">
            <label for="email" className="contact-label">
              Email
            </label>
            <input
              id="email"
              type="text"
              placeholder="e.g. John@gmail.com"
              onChange={(e) => onInputChange(e)}
              className="contact-input form-control outline-none shadow-none"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                  message: "Email is Invalid",
                },
                maxLength: {
                  value: 55,
                  message: "Max length is 55",
                },
              })}
            />
            <span className="text-danger fs-12">
              {errors.email && errors.email.message}
            </span>
          </div>

          <div className="col-sm-6  col-12 py-2">
            <label for="phone" className="contact-label">
              Phone
            </label>
            <input
              id="phone"
              type="text"
              placeholder="(000) 000 00 00"
              maxLength={10}
              onChange={(e) => onInputChange(e)}
              className="contact-input form-control outline-none shadow-none"
              {...register("phone", {
                required: "Phone Number is required",
                pattern: {
                  value: /^[6-9]{1}[0-9]{9}$/,
                  message: "Phone Number is Invalid",
                },
                maxLength: {
                  value: 10,
                  message: "Max length is 10",
                },
              })}
              onKeyPress={(event) => {
                keyPress(event, /^\d+$/);
              }}
            />
            <span className="text-danger fs-12">
              {errors.phone && errors.phone.message}
            </span>
          </div>

          <div className="col-sm-6 col-12 py-2">
            <label for="address" className="contact-label">
              Address
            </label>
            <input
              id="address"
              type="text"
              placeholder="Enter Address"
              className="contact-input form-control outline-none shadow-none"
              onChange={(e) => onInputChange(e)}
              {...register("address", {
                required: "Address field is required",
                maxLength: {
                  value: 55,
                  message: "Max length is 55",
                },
              })}
            />
            <span className="text-danger fs-12">
              {errors.address && errors.address.message}
            </span>
          </div>

          <div className="col-sm-6 col-12 py-2">
            <label for="pan_number" className="contact-label">
              Pan Number
            </label>
            <input
              id="pan_number"
              type="text"
              placeholder="Enter Pan Number"
              className="contact-input form-control outline-none shadow-none"
              onChange={(e) => onInputChange(e)}
              {...register("pan_number", {
                required: "Pan Number is required",
                pattern: {
                  value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                  message: "Pan Number is Invalid",
                },
                maxLength: {
                  value: 10,
                  message: "Max length is 10",
                },
              })}
            />
            <span className="text-danger fs-12">
              {errors.pan_number && errors.pan_number.message}
            </span>
          </div>

          <div className="col-sm-6 col-12 py-2">
            <label for="amount" className="contact-label">
              Loan Amount
            </label>
            <input
              id="amount"
              type="text"
              placeholder="Loan Amount"
              maxLength={10}
              onChange={(e) => onInputChange(e)}
              className="contact-input form-control outline-none shadow-none"
              {...register("amount", {
                required: "Loan amount is required",
                pattern: {
                  value: /^\d+$/,
                  message: "Loan amount is Invalid",
                },
                maxLength: {
                  value: 10,
                  message: "Max length is 10",
                },
              })}
              onKeyPress={(event) => {
                keyPress(event, /^\d+$/);
              }}
            />
            <span className="text-danger fs-12">
              {errors.amount && errors.amount.message}
            </span>
          </div>

          <div className="col-sm-6 col-12 py-2">
            <label for="loan" className="contact-label">
              Select Loan Type
            </label>
            <select
              className="form-select contact-select  outline-none shadow-none"
              aria-label="Default select example"
              onChange={(e) => onInputChange(e)}
              {...register("loan", {
                required: "Loan Type is required",
              })}
            >
              <option selected className="contact-select-default">
                Open this select menu
              </option>
              <option value="Home Loan">Home Loan</option>
              <option value="Vehicle Loan">Car Loan</option>
              <option value="Personal Loan">Personal Loan</option>
              <option value="Agiculture Loan">Agiculture Loan</option>
              <option value="Business Loan">Business Loan</option>
              <option value="Gold Loan">Gold Loan</option>
            </select>
            <span className="text-danger fs-12">
              {errors.loan && errors.loan.message}
            </span>
          </div>

          <div className="col-12 py-2">
            <label for="message" className="contact-label">
              Message
            </label>
            <textarea
              className="contact-input form-control outline-none shadow-none"
              id="message"
              onChange={(e) => onInputChange(e)}
              placeholder="Message"
              rows="3"
              {...register("message", {
                required: "Message is required",
              })}
            ></textarea>
            <span className="text-danger fs-12">
              {errors.message && errors.message.message}
            </span>
          </div>

          <div className="col-12 py-2">
            <button type="submit" className="btn contact-btn">
              Send Message
            </button>
          </div>
        </div>
      </form>
    </section>
  );
}

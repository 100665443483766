import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Home from "../components/Home/Home";
import About from "../components/About/about";
import Intro from "../components/Intro/Intro";
import Score from "../components/Score/Score";
import Service from "../components/Service/Sevice";
import Faq from "../components/Faq/Faq";
import Contact from "../components/Contact/Contact";
import Footer from "../components/Footer/Footer";

function Landing() {
  return (
    <>
      <div>
        <Navbar />
        <Home />
        <About />
        <Intro />
        <Score />
        <Service />
        <Faq />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default Landing;

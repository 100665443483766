import React from "react";
import "./Service.css";
import bg from "../../assets/images/Ourservice_bg.svg";
import loan from "../../assets/icons/our-section/loan-01.svg";
// import bg from "../../assets/images/Ourservice_bg.svg";
// import bg from "../../assets/images/Ourservice_bg.svg";
// import bg from "../../assets/images/Ourservice_bg.svg";

function Service() {
  return (
    <>
      <section className="container-fluid service-section px-0" id="service">
        <div className="sevice-content-div">
          <div className="service-img-bg d-lg-block d-none">
            <img src={bg} alt="img" />
          </div>
          <div className="service-card-section">
            <div className="row py-lg-0 py-5 px-3 mx-0">
              <div className="col-12">
              {/* <h4 className="about-title">Our Services</h4> */}
                <h1 className="service-h1">Our Services</h1>
              </div>
              <div className="col-lg-4 col-sm-6 service-card-padding d-flex align-items-center justify-content-center">
                <div className="card service-card">
                  <div className="card-body">
                    <div className="horizontal-circle">Loan</div>
                    <div className="circle-1"></div>
                    <div className="circle-2"></div>
                    <div className="circle-3">
                      <img src={loan} alt="img" />
                    </div>
                    <div>
                      <p className="service-card-p">
                        Top health and other claims for family welfare and
                        future
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6 service-card-padding d-flex align-items-center justify-content-center">
                <div className="card service-card">
                  <div className="card-body">
                    <div className="horizontal-circle">Insurance</div>
                    <div className="circle-1"></div>
                    <div className="circle-2"></div>
                    <div className="circle-3">
                      <img src={loan} alt="img" />
                    </div>
                    <div>
                      <p className="service-card-p">
                        Top health and other claims for family welfare and
                        future
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6 service-card-padding d-flex align-items-center justify-content-center">
                <div className="card service-card">
                  <div className="card-body">
                    <div className="horizontal-circle">Investments</div>
                    <div className="circle-1"></div>
                    <div className="circle-2"></div>
                    <div className="circle-3">
                      <img src={loan} alt="img" />
                    </div>
                    <div>
                      <p className="service-card-p">
                        Top health and other claims for family welfare and
                        future
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6 service-card-padding d-flex align-items-center justify-content-center">
                <div className="card service-card">
                  <div className="card-body">
                    <div className="horizontal-circle">Credit Cards</div>
                    <div className="circle-1"></div>
                    <div className="circle-2"></div>
                    <div className="circle-3">
                      <img src={loan} alt="img" />
                    </div>
                    <div>
                      <p className="service-card-p">
                        Top health and other claims for family welfare and
                        future
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6 service-card-padding d-flex align-items-center justify-content-center">
                <div className="card service-card">
                  <div className="card-body">
                    <div className="horizontal-circle">DSA Registration</div>
                    <div className="circle-1"></div>
                    <div className="circle-2"></div>
                    <div className="circle-3">
                      <img src={loan} alt="img" />
                    </div>
                    <div>
                      <p className="service-card-p">
                        Top health and other claims for family welfare and
                        future
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6 service-card-padding py-4 d-flex align-items-center justify-content-center">
                <div className="card service-card">
                  <div className="card-body">
                    <div className="horizontal-circle">EMI Calculator</div>
                    <div className="circle-1"></div>
                    <div className="circle-2"></div>
                    <div className="circle-3">
                      <img src={loan} alt="img" />
                    </div>
                    <div>
                      <p className="service-card-p">
                        Top health and other claims for family welfare and
                        future
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Service;

import React from "react";
import scoreImage from "../../assets/credit score-01.svg";
import "./Score.css";

function Score() {
  return (
    <>
      <section>
        <div className="container score-section">
          <div className="score-content-div">
            <div className="row">
              <div className="col-lg-6 col-12 d-flex align-items-center justify-content-center">
                <div className="score-image">
                  <img src={scoreImage} alt="img" />
                </div>
              </div>
              <div className="col-lg-6 col-12 mt-lg-0 mt-4">
                <div>
                  <h3 className="score-head-h3">
                    FREE! Get your Present Credit Score
                  </h3>
                  <p className="score-head-p">
                    This 3 digit value will validate and have control over on
                    your capitals
                  </p>

                  <div>
                    <div className="mt-lg-5 mt-4">
                      <h6 className="score-h6">Customized offers</h6>
                      <p className="score-p">
                        Based on scores, offers are customized on your
                        eligibility
                      </p>
                    </div>

                    <div className="mt-4">
                      <h6 className="score-h6">Ease of Approvals</h6>
                      <p className="score-p">
                        Possibility of chances to receive your credit card /
                        loan is high
                      </p>
                    </div>

                    <div className="mt-4">
                      <h6 className="score-h6">
                        Control Over your Finance / Monetary
                      </h6>
                      <p className="score-p">
                        Based on your score, it shows a better future on your
                        capitals & helps to improve them.
                      </p>
                    </div>

                    <div className="mt-4">
                      <h6 className="score-h6">Get Free Credit Score!</h6>
                      <p className="score-p">
                        Know your current creditworthiness free! from Credit
                        Bazaar
                      </p>
                    </div>
                  </div>

                  <div className="mt-4">
                    <a href="#" className="score-btn btn btn-md">Get Your Credit Score</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Score;

import React from "react";
import "./about.css";
import aboutus from "../../assets/about-us-01.svg";
function About() {
  return (
    <>
      <section className="container about-section" id="about">
        <div className="about-content-div">
          <h4 className="about-title">About Us</h4>
          <div className="row">
            <div className="col-lg-6 col-12 d-flex align-items-center justify-content-center">
              <div className="about-image">
                <img src={aboutus} alt="img" />
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-lg-0 mt-5 d-flex align-items-center justify-content-start">
              <div>
                <p className="about-p">
                  Credit Bazaar is a Fintech application that provides free
                  credit reports from multiple bureaus and offers a customized
                  credit card & loan to every individual. At its core, it seeks
                  to improve and automate the delivery and use of financial
                  services. ​​​Credit Bazaar helps consumers, companies, and
                  business owners better manage their financial operations
                  seamlessly in digital and reduces the processing time by
                  utilizing the application in one touch.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
